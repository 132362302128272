import * as XLSX from "xlsx";

export const ROUTER_URL = {
  POC_UPLOAD_LIST: "POC UPLOAD LIST",
  POC_STATUS_HISTORY: "POC STATUS HISTORY",
  POLICY_ENQUIRY: "POLICY ENQUIRY",
  POLICY_DATA_VERIFICATION: "POLICY DATA VERIFICATION",
  ENDORSEMENT: "ENDORSEMENT",
  ENDORSEMENT_NEW: "ENDORSEMENT NEW",
  RENEWAL: "RENEWAL",
  SUPPORT: "SUPPORT",
  POLICY_IMPORT: "POLICY IMPORT",
  PAN_VALIDATION: "PAN VALIDATION",
  POLICY_REPORT: "INSA MIS REPORT",
  INSA_MATCH: "INSA MATCH"
};

export const LABEL_NAME = {
  INSURER_CODE_NAME: "Insurer Code/Name*",
  FROM_DATE: "From Date*",
  TO_DATE: "To Date*",
  STATUS: "Status*",
  EIA_NO: "eIA No",
  POLICY_NO: "Policy No*",
  POLICY_TYPE: "Policy Type*",
  FORMAT_TYPE: "Format Type",
  INSURER_NOTE: " (Mandatory fields Insurer Code/Name, From Date,To Date )",
  POCLIST_NOTE:
    "(Mandatory fields Insurer Code/Name, From Date,To Date,Status )",
  POLICY_ENQUIRY_NOTE:
    "(Policy type is mandatory and either eIA No or policy number is mandatory)",
  RENEWAL_NOTE: "(Mandatory fields Policy Type, From Date, To Date)",
  ENDORSEMENT_NOTE: "(Mandatory fields Policy Type, From Date, To Date)",
  POLICY_IMPORT_NOTE: "(Policy type is mandatory and either Lot No or Lot Date and Data File Name is mandatory)"

};

export const SERVICE_TYPE = {
  POC: "Policy Conversion",
  REN: "Renewal",
  END: "Endorsement",
};

export const POLICY_TYPE = {
  1: "Life",
  2: "Motor",
  3: "Health",
};

export const POLICY_VERIFICATION_STATUS = {
  Y: "Yes",
  N: "No",
};

export const exportAsExcel = (fileName, data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, fileName);
};
